<template>
  <div class="pa-1">
    <div class="text-center">
      <v-menu offset-y max-height="400">
        <template v-slot:activator="{on, attrs}">
          <v-btn
            color="info"
            v-bind="attrs"
            v-on="on"
            :disabled="items.length === 0"
          >
            {{ getText }}
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="selected">
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import {precioFix} from '@/utils/vehiculo';

export default {
  name: 'menuDinamico',
  props: {
    value: {
      type: [String, Number],
    },
    fix: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    selected: null,
  }),
  computed: {
    getText() {
      if (this.items[this.selected]) {
        return this.items[this.selected];
      }
      return this.placeholder;
    },
  },
  watch: {
    selected(val) {
      this.$emit('change', this.items[val]);
    },
    value(val) {
      let pos;

      if (this.fix) pos = this.items.indexOf(precioFix(val));
      else pos = this.items.indexOf(val);
      if (pos !== -1) {
        this.selected = pos;
      }
    },
  },
};
</script>
