<template>
  <v-container style="background-color: white">
    <vue-headful
      title="Vehiculos - Automotriz Petersen"
      description="En Nuestro Stock encontraras Gran variedad de vehiculos para ti."
    />
    <v-expansion-panels
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="expansionPanel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          color="info"
          class="white--text font-weight-bold"
        >
          Filtros
          <template v-slot:actions>
            <v-icon color="white">mdi-chevron-down</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <buscador-vehiculo @click.prevent></buscador-vehiculo>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <buscador-vehiculo v-else></buscador-vehiculo>
    <div v-if="stock2.length === 0" class="mt-12">
      <v-layout justify-center v-if="stock2.length === 0">
        <br /><br /><br />
        <v-progress-circular indeterminate color="info"></v-progress-circular>
      </v-layout>
    </div>
    <div
      v-if="stock2.length !== 0 && carros.length === 0"
      class="mt-6"
      :key="stock2.length"
    >
      <v-row align="center" no-gutters>
        <v-col
          cols="12"
          sm="6"
          md="6"
          xl="3"
          lg="4"
          v-for="(vehiculo, index) of stock2"
          :key="index"
          class="pa-1"
        >
          <vehiculo-display
            v-model="vehiculoSelected"
            :vehiculo="vehiculo"
            :margin="false"
            :detail="true"
          ></vehiculo-display>
        </v-col>
      </v-row>
    </div>
    <v-row justify="center" no-gutters>
      <v-col cols="12" :xs="12" :sm="12" :md="6" :lg="6" :xl="4" class="my-3">
        <v-row
          align="center"
          :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'space-between'"
          no-gutters
        >
          <policy />
          <term />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import VehiculoDisplay from '@/components/vehiculo/vehiculoDisplay';
import BuscadorVehiculo from '@/components/vehiculo/buscadorVehiculo';
import Policy from '@/components/policy.vue';
import Term from '@/components/term.vue';

export default {
  name: 'Stock',
  components: {
    Term,
    Policy,
    BuscadorVehiculo,
    VehiculoDisplay,
  },
  data: () => ({
    expansionPanel: false,
    model: 0,
    selectOrden: 'El más Reciente',
    ordenar: [
      'El más Reciente',
      'Precio (alto a bajo)',
      'Precio (barato a caro)',
      'Nombre A-Z',
      'Nombre Z-A',
      'Kilometraje',
    ],
    vehiculoSelected: {},
    panel: undefined,
    open: false,
    vehicle: {},
    isToOpen: null,
  }),
  computed: {
    ...mapState(['stock', 'stock2', 'carros', 'stockGeneral']),
    isVehiculoSelected() {
      // eslint-disable-next-line no-prototype-builtins
      return this.vehicle && this.vehicle.hasOwnProperty('id');
    },
  },
  watch: {
    stockGeneral(val) {
      if (val.length !== 0) {
        this.openVehiculo(this.idToOpen);
      }
    },
    vehiculoSelected(val) {
      if (!val.id) return;
      this.vehicle = this.stockGeneral.filter(
        (vehiculo) => vehiculo.id === val.id
      )[0];
      this.open = true;
      if (
        this.$router.history.current.name !== 'resultado-vehiculo' &&
        this.$router.history.current.name !== 'petersen'
      ) {
        this.$router.push({
          name: 'resultado-vehiculo',
          params: {
            vehiculoId: this.vehicle.id,
          },
        });
      }
    },
  },
  created() {
    window.addEventListener('closeModal', this.closeModal, false);
  },

  beforeDestroy() {
    window.removeEventListener('closeModal', this.closeModal, false);
  },
  mounted() {
    const id = this.$router.history.current.params.vehiculoId;
    if (id) {
      this.idToOpen = id;
      this.openVehiculo(id);
    }
  },
  methods: {
    ...mapMutations(['SET_VEHICULO']),
    precioFix(val) {
      return '$' + parseInt(val).toLocaleString('de-DE');
    },
    closeModal() {
      this.open = false;
      this.vehiculoSelected = {};
    },
    message(val) {
      let item = `https://wa.me/56944436444?text=Hola%20Petersen%20Me%20Gustaria%20Cotizar%20Este%20Vehiculo%20${val}`;
      window.open(item, '_blank');
    },
    openVehiculo(id) {
      if (this.stockGeneral.length === 0 || !id) return;
      this.vehicle = this.stockGeneral.filter(
        (vehiculo) => vehiculo.id === id
      )[0];

      this.open = true;
      if (
        this.$router.history.current.name !== 'resultado-vehiculo' &&
        this.$router.history.current.name !== 'petersen'
      ) {
        this.$router.push({
          name: 'resultado-vehiculo',
          params: {
            vehiculoId: this.vehicle.id,
          },
        });
      }
    },
  },
};
</script>
