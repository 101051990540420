<template>
  <div :class="$vuetify.breakpoint.smAndDown ? 'mt-4' : ''">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <br v-if="$vuetify.breakpoint.mdAndUp" />
        <v-row align="center" justify="center" no-gutters>
          <auto-completar
            v-model="formData.categoria"
            placeholder="Carroceria"
            :items="categorias"
            class="mx-1"
          ></auto-completar>
          <auto-completar
            v-model="formData.transmition"
            placeholder="Transmisión"
            :items="transmissions"
            class="mx-1"
          ></auto-completar>
          <auto-completar
            v-model="formData.traction"
            placeholder="Tracción"
            :items="tractions"
            class="mx-1"
          ></auto-completar>
          <auto-completar
            v-model="formData.fuel"
            placeholder="Combustible"
            :items="carsFuels"
            class="mx-1"
          ></auto-completar>
        </v-row>
        <v-row align="center" justify="center" no-gutters>
          <auto-completar
            v-model="formData.marca"
            placeholder="Marca"
            :items="marcas"
            class="mx-1"
          ></auto-completar>
          <auto-completar
            v-model="formData.modelo"
            placeholder="Modelo"
            :isDisabled="!formData.marca"
            :items="modelos"
            class="mx-1"
          ></auto-completar>
          <auto-completar
            v-model="formData.version"
            placeholder="Version"
            :isDisabled="!formData.modelo"
            :items="versions"
            class="mx-1"
          ></auto-completar>
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-row align="center" justify="center" no-gutters>
          <v-col cols="12" md="8" xl="8" lg="8">
            <v-row
              v-if="$vuetify.breakpoint.mdAndUp"
              align="center"
              justify="space-between"
              no-gutters
            >
              <menu-dinamico
                v-model="formData.anoMin"
                :items="anos"
                placeholder="Año Minimo"
                @change="setAnoMin"
              ></menu-dinamico>
              <menu-dinamico
                v-model="formData.anoMax"
                :items="filterAnos"
                placeholder="Año Maximo"
                @change="setAnoMax"
              ></menu-dinamico>

              <menu-dinamico
                v-model="formData.precioMin"
                fix
                :items="filterPreciosMin"
                placeholder="Precio Minimo"
                @change="setPrecioMin"
              ></menu-dinamico>
              <menu-dinamico
                v-model="formData.precioMax"
                fix
                :items="filterPreciosMax"
                placeholder="Precio Maximo"
                @change="setPrecioMax"
              ></menu-dinamico>
            </v-row>
            <v-row v-else align="center" justify="space-between" no-gutters>
              <v-col cols="12">
                <v-row align="center" justify="space-between" no-gutters>
                  <menu-dinamico
                    v-model="formData.anoMin"
                    :items="anos"
                    placeholder="Año Minimo"
                    @change="setAnoMin"
                  ></menu-dinamico>
                  <menu-dinamico
                    v-model="formData.anoMax"
                    :items="filterAnos"
                    placeholder="Año Maximo"
                    @change="setAnoMax"
                  ></menu-dinamico>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row align="center" justify="space-between" no-gutters>
                  <menu-dinamico
                    v-model="formData.precioMin"
                    fix
                    :items="filterPreciosMin"
                    placeholder="Precio Minimo"
                    @change="setPrecioMin"
                  ></menu-dinamico>
                  <menu-dinamico
                    v-model="formData.precioMax"
                    fix
                    :items="filterPreciosMax"
                    placeholder="Precio Maximo"
                    @change="setPrecioMax"
                  ></menu-dinamico>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import AutoCompletar from '@/components/core/autoCompletar';
import MenuDinamico from '@/components/core/menuDinamico';
import {
  deepCopy,
  findClosestValue,
  normalizeString,
  subtractNumber,
} from '@/utils/general';
import {precioFix} from '@/utils/vehiculo';

export default {
  name: 'buscadorVehiculo',
  components: {MenuDinamico, AutoCompletar},
  data: () => ({
    formData: {
      categoria: null,
      marca: null,
      modelo: null,
      version: null,
      anoMin: null,
      anoMax: null,
      precioMin: null,
      precioMax: null,
      transmition: null,
      traction: null,
      fuel: null,
    },
    formCopy: {
      categoria: null,
      marca: null,
      modelo: null,
      version: null,
      anoMin: null,
      anoMax: null,
      precioMin: null,
      precioMax: null,
      transmition: null,
      traction: null,
      fuel: null,
    },
    render: true,
  }),
  mounted() {
    const {$route, formData} = this;
    if (!$route.query) {
      return;
    }

    const otherFields = {
      bodywork: 'categoria',
      brand: 'marca',
      model: 'modelo',
      maxPrice: 'precioMax',
      maxYear: 'anoMax',
      minPrice: 'precioMin',
      minYear: 'anoMin',
      transmission: 'transmition',
    };

    const entries = Object.entries($route.query);

    for (const [key, value] of entries) {
      if (key in formData) {
        this.formCopy[key] = value;
      } else if (otherFields[key] in formData) {
        this.formCopy[otherFields[key]] = value;
      }
    }
  },
  computed: {
    ...mapState([
      'categorias',
      'marcas',
      'modelos',
      'anos',
      'versions',
      'precios',
      'carsFuels',
      'transmissions',
      'tractions',
    ]),
    allData() {
      const {
        categorias,
        marcas,
        modelos,
        anos,
        versions,
        precios,
        carsFuels,
        transmissions,
        tractions,
      } = this;

      return {
        categorias,
        marcas,
        modelos,
        anos,
        versions,
        precios,
        carsFuels,
        transmissions,
        tractions,
      };
    },
    filterAnos() {
      return this.anos.filter((ano) => ano > (this.anoMin || 0));
    },
    filterPreciosMin() {
      return this.precios.map((precio) => precioFix(precio));
    },
    filterPreciosMax() {
      const prices = this.precios || [];

      return prices
        .filter((precio) => {
          if (precio > this.formData.precioMin || 0) {
            return precio;
          }
        })
        .map((precio) => precioFix(precio));
    },
  },
  watch: {
    allData: {
      deep: true,
      immediate: true,
      handler(oldVal, newVal) {
        if (JSON.stringify(oldVal) === JSON.stringify(newVal)) return;
        this.setData(newVal);
      },
    },
    formData: {
      deep: true,
      immediate: true,
      handler(val) {
        this.FIND_CARS(val);
      },
    },
    'formData.marca': {
      deep: true,
      handler(newVal, oldVal) {
        if (this.render) return;
        if (newVal !== oldVal) {
          this.formData.modelo = null;
          this.formData.version = null;
        }

        this.FIND_CARS(this.formData);
      },
    },
    'formData.modelo': {
      deep: true,
      handler(newVal, oldVal) {
        if (this.render) {
          this.render = false;
          return;
        }
        if (newVal !== oldVal) {
          this.formData.version = null;
        }

        this.FIND_CARS(this.formData);
      },
    },
  },
  methods: {
    ...mapActions(['FIND_CARS', 'FIND_CARS']),
    findClosestValue,
    setAnoMin(e) {
      this.formData.anoMin = Number(e);
    },
    setAnoMax(e) {
      this.formData.anoMax = Number(e);
    },
    setPrecioMin(e) {
      this.formData.precioMin = subtractNumber(this.reFormatNumber(e));
    },
    setPrecioMax(e) {
      if (!e) this.formData.precioMax = null;
      else this.formData.precioMax = subtractNumber(this.reFormatNumber(e));
    },
    reFormatNumber(number) {
      if (!number) {
        return null;
      }
      return number.split('$')[1].split('.').join('');
    },
    setData(val) {
      if (!val) return;
      let form;

      if (this.render) {
        form = deepCopy(this.formCopy);
      } else {
        form = deepCopy(this.formData);
      }

      const entriesForm = Object.entries(form);
      const entries = Object.entries(val);

      for (const [key, value] of entriesForm) {
        if (value) {
          for (const [keyData] of entries) {
            const val = this[keyData].find(
              (val) => normalizeString(val) === normalizeString(value)
            );
            if (val) {
              form[key] = val;
            }
          }
        }
      }

      form.precioMax = findClosestValue(form.precioMax, this.filterPreciosMax);
      form.precioMin = findClosestValue(form.precioMin, this.filterPreciosMin);

      this.formData = form;
    },
  },
};
</script>

<style scoped></style>
