<template>
  <div>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="600px"
    >
      <v-card>
        <v-card-title>Condiciones de servicio</v-card-title>
        <v-card-text>
          1. <strong>Aceptación de las Condiciones:</strong> Al utilizar nuestra
          aplicación, el usuario acepta cumplir con estas Condiciones de
          Servicio. Si no está de acuerdo con alguna de las condiciones, no debe
          utilizar esta aplicación. <br />
          2. <strong>Servicios Ofrecidos:</strong> A través de nuestra
          aplicación, AUTOMOTRIZ PETERSEN envía mensajes informativos
          relacionados con la calidad de servicio, promociones e información
          sobre servicios contratados. <br />3. <strong>Registro:</strong> Para
          utilizar nuestra aplicación, el usuario debe registrarse
          proporcionando información personal válida y actualizada. Es
          responsabilidad del usuario mantener la seguridad de su cuenta.
          <br />4. <strong>Uso Adecuado:</strong> El usuario se compromete a
          utilizar la aplicación de manera adecuada y legal, sin enviar mensajes
          ofensivos, difamatorios o que infrinjan los derechos de terceros.
          <br />5. <strong>Limitación de Responsabilidad:</strong> AUTOMOTRIZ
          PETERSEN no será responsable de ningún daño o pérdida resultante del
          uso o la imposibilidad de usar la aplicación. <br />6.
          <strong>Cambios en las Condiciones:</strong>
          AUTOMOTRIZ PETERSEN se reserva el derecho de modificar estas
          Condiciones de Servicio en cualquier momento. Si se realizan cambios
          significativos, se notificará a los usuarios a través de la aplicación
          o por otros medios.<br />
          7. <strong>Terminación:</strong> AUTOMOTRIZ PETERSEN puede suspender o
          terminar el acceso de un usuario a la aplicación en caso de
          incumplimiento de estas condiciones. <br />8.
          <strong>Contacto:</strong> Para preguntas o inquietudes relacionadas
          con esta política de privacidad, por favor contacta a
          <strong @click="mail" class="text--primary" style="cursor: pointer">
            ventas@automotrizpetersen.cl
          </strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      dense
      small
      text
      color="primary"
      @click="dialog = true"
      class="text--primary no-uppercase"
      >Condiciones de servicio
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'term',
  components: {},
  data: () => ({
    dialog: false,
  }),
  methods: {
    mail() {
      window.location.href = `mailto:ventas@automotrizpetersen.cl`;
    },
  },
};
</script>

<style scoped>
.no-uppercase {
  text-transform: none !important;
}
</style>
