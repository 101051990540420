<template>
  <div>
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="600px"
    >
      <v-card>
        <v-card-title>Política de Privacidad</v-card-title>
        <v-card-text>
          1. <strong>Información que recopilamos:</strong> Recopilamos
          información proporcionada por el cliente al registrarse en nuestra
          aplicación, incluyendo, pero no limitado a, nombre, número de teléfono
          y dirección de correo electrónico. <br />2.
          <strong>Uso de la Información:</strong> Utilizamos la información
          recopilada para enviar mensajes informativos relacionados con la
          calidad de servicio, promociones e información sobre servicios
          contratados. No vendemos, alquilamos ni compartimos esta información
          con terceros sin el consentimiento del cliente. <br />3.
          <strong>Seguridad:</strong> Tomamos medidas de seguridad para proteger
          la información del cliente. Aunque hacemos todo lo posible para
          proteger la información del cliente, no podemos garantizar la
          seguridad de la información transmitida a través de nuestra
          aplicación. <br />4. <strong>Consentimiento:</strong> Al registrarse
          en nuestra aplicación, el cliente consiente el uso de su información
          como se describe en esta política de privacidad. <br />5.
          <strong>Cambios a esta Política:</strong> Podemos actualizar esta
          política de privacidad ocasionalmente. Si hacemos cambios
          significativos, notificaremos a los clientes a través de la aplicación
          o por otros medios. <br />6. <strong>Contacto:</strong> Para preguntas
          o inquietudes relacionadas con esta política de privacidad, por favor
          contacta a
          <strong @click="mail" class="text--primary" style="cursor: pointer">
            ventas@automotrizpetersen.cl
          </strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      dense
      small
      text
      color="primary"
      @click="dialog = true"
      class="text--primary no-uppercase"
      >Política de privacidad
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'policy',
  components: {},
  data: () => ({
    dialog: false,
  }),
  methods: {
    mail() {
      window.location.href = `mailto:ventas@automotrizpetersen.cl`;
    },
  },
};
</script>

<style scoped>
.no-uppercase {
  text-transform: none !important;
}
</style>
